import React from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from "./assets/img/bg.png";

const Hakkimda = () => {
  return (
    <main id="main" data-aos="fade" data-aos-delay="500">
      <section id="about" className="about">
        <div className="container">
          <div className="row gy-4 justify-content-center" >
            <div className="col-lg-4">
              <img src="../assets/img/portfolio/pp.png" className="img-fluid" alt="Profile" />
            </div>
            <div className="col-lg-5 content myHover">
              <h2 className="myfont myletter">Founder of Stark Bilişim</h2>
              <div className="row">
                <div className="col-lg-6">
                  <ul>
                    <li><i className="bi bi-chevron-right"></i> <strong>Website:</strong> <span>www.starkbilisim.com</span></li>
                    <li><i className="bi bi-chevron-right"></i> <strong>City:</strong> <span>EDİRNE</span></li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul>
                    <li><i className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>Founder</span></li>
                    <li><i className="bi bi-chevron-right"></i> <strong>Email:</strong> <span>berkant@starkbilisim.com</span></li>
                  </ul>
                </div>
              </div>
              <p className="py-3"> Henüz ilkokuldayken Sivil Toplum Kuruluşlarıyla tanıştım. Küçük yaşlarımdan itibaren atıldığım Sivil Toplum macerasına halen devam etmekteyim. Dezavantajlı gruplarla, özellikle gençlik alanında çalışmalar yürütmekteyim. Aktif olarak bir Sivil Toplum Kuruluşunda Proje Koordinatörlüğü yapmaktayım.
                Bazı Avrupa Birliği projelerinde gönüllü olarak yer almaktayım.
                Ayrıca lise sıralarında Veri Tabanı Programcılığıyla başladığım yazılım hayatıma kendimi geliştirerek devam etmekteyim.

                Beni yaratıcı ve yenilikçi düşünce süreçlerine teşvik eden ve aynı zamanda çalışma hayatımdaki stresi azaltmama yardımcı olan ilgi alanlarıma vakit ayırmaktan keyif duyarım.

                LinkedIn hesabıma göz atarak daha fazla bilgi edinebilirsiniz. Bağlantı kurmak için lütfen mesaj atın, işbirliği fırsatlarını bekliyorum.</p>
              <a href="mailto:berkant@starkbilisim.com" className="myBtn myfont myletter">MAIL</a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Hakkimda;
