import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <main id="main" data-aos="fade" data-aos-delay="500">
      <section id="about" className="about">
        <div className="container">
          <div className="row gy-4 justify-content-center">
            <div className="col-lg-4">
              <img src="assets/img/portfolio/pp.png" className="img-fluid" alt="" />
            </div>
            <div className="col-lg-5 content myHover">
              <h2 className="myfont myletter">Founder of Stark Bilişim</h2>
              <div className="row">
                <div className="col-lg-6">
                  <ul>
                    <li><i className="bi bi-chevron-right"></i> <strong>Website:</strong> <span>www.starkbilisim.com</span></li>
                    <li><i className="bi bi-chevron-right"></i> <strong>City:</strong> <span>EDİRNE</span></li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul>
                    <li><i className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>Founder</span></li>
                    <li><i className="bi bi-chevron-right"></i> <strong>Email:</strong> <span>berkant@starkbilisim.com</span></li>
                  </ul>
                </div>
              </div>
              <p className="py-3">
                I became acquainted with Non-Governmental Organizations during my primary school years. Since a young age, I have been actively involved in the world of civil society, and my journey continues to this day. I focus on working with disadvantaged groups, especially in the youth sector. Currently, I am a Project Coordinator at an active NGO.

                I also volunteer for several European Union projects. My journey in software development began in high school with Database Programming, and I have continued to enhance my skills ever since.

                I take pleasure in dedicating time to my interests, which encourage creative and innovative thinking and help reduce stress in my professional life.

                Feel free to visit my LinkedIn profile for more information. Please send me a message to connect. I look forward to exploring collaboration opportunities.
              </p>
              <a href="mailto:berkant@starkbilisim.com" className="myBtn myfont myletter">MAIL</a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default About;
