// anasayfa.js

import React from "react";
import { Link } from "react-router-dom";
import backgroundImage from "./assets/img/system.conf.jpg";

const Anasayfa = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          opacity: "40%",
        }}
      ></div>

      <div id="large-header" className="large-header">
        <div className="mainContainer">
          <h1 className="myletter myfont myTextAlign mysecim">
            Hakkımda
          </h1>
          <p className="myTextAlign mysecim">
            Henüz ilkokuldayken Sivil Toplum Kuruluşlarıyla tanıştım. Küçük yaşlarımdan itibaren atıldığım Sivil Toplum macerasına halen devam etmekteyim. Dezavantajlı gruplarla, özellikle gençlik alanında çalışmalar yürütmekteyim. Aktif olarak bir Sivil Toplum Kuruluşunda Proje Koordinatörlüğü yapmaktayım.
            Bazı Avrupa Birliği projelerinde gönüllü olarak yer almaktayım.
            Ayrıca lise sıralarında Veri Tabanı Programcılığıyla başladığım yazılım hayatıma kendimi geliştirerek devam etmekteyim.

            Beni yaratıcı ve yenilikçi düşünce süreçlerine teşvik eden ve aynı zamanda çalışma hayatımdaki stresi azaltmama yardımcı olan ilgi alanlarıma vakit ayırmaktan keyif duyarım.

            LinkedIn hesabıma göz atarak daha fazla bilgi edinebilirsiniz. Bağlantı kurmak için lütfen mesaj atın, işbirliği fırsatlarını bekliyorum.
          </p>
          <Link to="/contact" className="myBtn myfont myletter">
            İletişime Geç
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Anasayfa;
