// Anasayfa.js
import React from "react";
import { Link } from "react-router-dom";
import backgroundImage from "./assets/img/system.conf.jpg";

const Home = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          opacity: "40%",
        }}
      ></div>

      <div className="mainContainer">
        <h1 className="myletter myfont myTextAlign mysecim">
          About Me
        </h1>
        <p className="myTextAlign mysecim">
          I became acquainted with Non-Governmental Organizations during my primary school years. Since a young age, I have been actively involved in the world of civil society, and my journey continues to this day. I focus on working with disadvantaged groups, especially in the youth sector. Currently, I am a Project Coordinator at an active NGO.

          I also volunteer for several European Union projects. My journey in software development began in high school with Database Programming, and I have continued to enhance my skills ever since.

          I take pleasure in dedicating time to my interests, which encourage creative and innovative thinking and help reduce stress in my professional life.

          Feel free to visit my LinkedIn profile for more information. Please send me a message to connect. I look forward to exploring collaboration opportunities.
        </p>
        {/* Link ile "contact" sayfasına yönlendirme */}
        <Link to="/contact" className="myBtn myfont myletter">
          Contact us
        </Link>
      </div>
    </div>
  );
};

export default Home;
